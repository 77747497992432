<template>
  <div class="page1">
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >预付报表，统计路内停车预付费缴费情况；适用于运营人员和财务人员了解预付情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img src="./Report/img/close.png" @click="closeShow = false" alt="" />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="96px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
                <my-component
                  ref="parkInput"
                  :areaIds="formInline.streetId ? formInline.streetId : formInline.areaId"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                >
                </my-component>
              </el-form-item>

              <el-form-item :label="$t('searchModule.Date_search')">
                <a-date-picker
                  ref="datePicker"
                  :YearShowHidden="selectkeys[3]"
                  :selectkeys="selectkeys"
                  :previousDate="previousDate"
                  :nextDate="nextDate"
                ></a-date-picker>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Merchant_affiliation')">
                <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >查询
              </el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                type=""
                icon="el-icon-upload2"
                @click="
                  pageNum = 1;
                  exportExcelReport();
                "
                :loading="loading"
                >导出
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper paddingB20">
        <div class="statusStyle">
          <span class="statusStyle_word">
            <span class="circle"></span>
            合计预付金额: {{ (preMoney / 100).toFixed(2) }} 元
          </span>
          <span class="statusStyle_word">
            <span class="circle"></span>
            预付抵扣总额: {{ (deductMoney / 100).toFixed(2) }} 元
          </span>
          <span class="statusStyle_word">
            <span class="circle"></span>
            退款总计: {{ (refundMoney / 100).toFixed(2) }} 元
          </span>
        </div>
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :header-cell-style="{
            background: '#EBEEF5',
            'font-size': '14px',
            'font-weight': 'bold',
            color: '#333333',
            height: '40px',
          }"
        >
          <el-table-column align="center" min-width="150" prop="statisticsDate" :label="$t('searchModule.date')">
          </el-table-column>
          <el-table-column align="center" min-width="150" prop="operationName" :label="$t('searchModule.Merchant_Name')">
          </el-table-column>
          <el-table-column align="center" min-width="150" prop="parkName" :label="$t('searchModule.Road_or_Parking_Lot')">
          </el-table-column>

          <el-table-column align="center" min-width="150" prop="parkAmount" label="泊位数">
          </el-table-column>

          <el-table-column align="center" min-width="150" prop="payCount" label="预付笔数">
          </el-table-column>
          <el-table-column align="center" min-width="150" label="预付金额（元）">
            <template slot-scope="scope">
              <p>{{ scope.row.preMoney | moneyHandling() }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="150" prop="deductCount" label="预付抵扣笔数">
          </el-table-column>
          <el-table-column align="center" min-width="150" label="预付抵扣金额（元）">
            <template slot-scope="scope">
              <p>{{ scope.row.deductMoney | moneyHandling() }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="150" prop="refundCount" label="预付退款笔数">
          </el-table-column>
          <el-table-column align="center" min-width="150" label="预付退款金额（元）">
            <template slot-scope="scope">
              <p>{{ scope.row.refundMoney | moneyHandling() }}</p>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper bgFFF">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="pageNum"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";

export default {
  name: "parkIncomeForm",
  data() {
    return {
      closeShow: true,
      selectkeys: ["date", "month", "week", "year"],
      previousDate: new Date(),
      nextDate: new Date(),
      deductMoney: 0,
      preMoney: 0,
      refundMoney: 0,
      pageNum: 1,
      pageSize: 15,
      total: 0,
      parkList: [],
      tableData: [],
      channelTypeList: [],
      searchType: "historyData",
      loading: false,
      type1: "c3",
      type2: "c4",
      formInline: {
        parkId: "",
        parkName: "",
        showDate: "0",
        startTime: "",
        endTime: "",
      },
    };
  },
  methods: {
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    moneyFormatter(row, column, cellValue, index) {
      if (cellValue) {
        return Number(cellValue / 100).toFixed(2);
      } else {
        return "0.00";
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    completeValue(item) {
      if (item) {
        this.formInline.parkId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parkId = "";
        this.formInline.parkName = "";
      }
    },
    /* 停车场 */
    parkData() {
      this.$axios
        .get("/acb/2.0/systems/loginUser/getParkName", {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          this.parkList = res.value.list;
        });
    },
    searchData() {
      if (this.formInline.parkId != "") {
        this.$refs.parkInput.setShowVal(this.formInline.parkName);
        this.getReport();
      } else {
        this.$refs.parkInput.setShowVal("");
        this.getReport();
      }
    },
    // 停车场收入报表 查询
    getReport() {
      this.$axios
        .get("/acb/2.0/preOrder/queryPreOrderStatisticsList", {
          data: {
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            parkId: this.formInline.parkId,
            // reportType: parseInt(this.formInline.showDate) + 1, // 必填，day/month
            reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list.list;
            this.total = res.value.list.total * 1 || 0;
            this.deductMoney = res.value.totalStatistics
              ? res.value.totalStatistics.deductMoney
              : 0;
            this.preMoney = res.value.totalStatistics ? res.value.totalStatistics.preMoney : 0;
            this.refundMoney = res.value.totalStatistics
              ? res.value.totalStatistics.refundMoney
              : 0;
          } else {
            this.tableData = [];
            this.total = 0;
          }
        });
    },
    getChannelTypeList() {
      this.$axios.get("/acb/2.0/channelType/list").then((res) => {
        console.log(res, "res");
        this.channelTypeList = res.value;
      });
    },
    // 停车场收入报表 导出
    exportExcelReport() {
      exportExcelNew("/acb/2.0/preOrder/preOrderStatisticsListExport", {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        parkId: this.formInline.parkId,
        // reportType: parseInt(this.formInline.showDate) + 1, // 必填，day/month
        reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startTime: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endTime: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
      });
    },
  },
  components: {
    myComponent,
  },
  beforeDestroy() {},
  created() {
    this.getChannelTypeList();
    this.parkData();
  },
  computed: {},
  mounted() {
    this.searchData();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper
  margin-top 28px;
  .el-pagination
    // padding-top 20px
    text-align right
.breadcrumb
  height 35px
.content
  .list
    margin-top 20px
    .el-col
      padding-bottom 18px
      height 320px
      .grid-content
        padding-top 15px
        height 100%
      h2
        font-size 18px
        text-align center
.tableWrapper >>> .el-table {
  width: 100%!important;
}
.tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar {
    width: 10px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
    background: transparent;
    border-radius: 10px;
  }

  // 滚动条的滑块样式
  .tableWrapper >>> .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgba(217, 225, 238, 1);
    border-radius: 10px;
  }
.tableWrapper {
  background: #ffffff;
  .statusStyle {
    margin-bottom:10px
    height: 51px;
    line-height: 51px;
    border-radius: 5px;
    border: 1px solid #E2E2EA;
    .statusStyle_word {
      margin-left: 20px;
      color: #7A8897;
    }
    .circle {
      display: inline-block;
      width: 11px;
      height: 11px;
      border-radius: 5px;
      margin-right: 9px;
    }
    .statusStyle_word:nth-child(1) .circle {
      background: #A461D8;
    }
    .statusStyle_word:nth-child(2) .circle {
      background: #82C43C;
    }
    .statusStyle_word:nth-child(3) .circle {
      background: #527BFF;
    }
  }
}
.info
  margin: 0 -20px 0 -20px;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);
  .info_content
    background: rgba(254, 242, 213, 1);
    color: rgba(255, 151, 74, 1);
  img
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
</style>
